<template>
	<div class="popup active">
		<div class="notice_popup int_prj">
            <div class="close">
                <img src="/images/close.png" alt="닫기" @click="$emit('close', false)"/>
            </div>
            
			<img class="prj_supply" src="/images/prj_supply.png" />
			<div v-if="param.parentProjectSeq" class="tit">공급기업 지정 프로젝트 수정</div>
            <div v-else class="tit">공급기업 지정 프로젝트 등록</div>
           
            <div class="prj_info_box">
                <div class="inner">
                    <p>{{param.projectNm}}</p>
                    <!-- <p>
                        공급기업 지정 프로젝트 제목입니다. 
                        2줄까지 보이고 말줄임된 상태입니다. 
                        단어기준으로 줄바꿈됩니다. 
                        2줄까지 보이고 말줄임된 상태입니다. 
                        단어기준으로 줄바꿈됩니다.
                    </p> -->
                    <div class="txt">
                        <div>구<nbsp n="15"/>분</div>
                        <div v-if="param.projectDivCd == '32'">제안단계</div>
                        <div v-else >수행예정 / 수주일 : {{param.contractYyyymmdd | date('yyyy. MM. dd')}}</div>
                    </div>
                    <div class="txt">
                        <div>개<nbsp n="15"/>요</div>
                        <div>{{param.projectDtlDesc}}</div>
                        <!-- <div>
                            공급기업 지정 프로젝트 내용입니다. 
                            2줄까지 보이고 말줄임된 상태입니다. 
                            단어기준으로 줄바꿈됩니다. 
                            공급기업 지정 프로젝트 내용입니다. 
                            2줄까지 보이고 말줄임된 상태입니다. 
                            단어기준으로 줄바꿈됩니다. 
                        </div> -->
                    </div>
                    <div class="txt">
                        <div>고<nbsp n="6"/>객<nbsp n="5"/>사</div>
                        <div>{{param.clientNm}}</div>
                    </div>
                    <div class="txt">
                        <div>프로젝트 기간</div>
                        <div>{{param.projectStartYyyymmdd | date('yyyy.MM.dd')}} - {{param.projectEndYyyymmdd | date('yyyy.MM.dd')}}</div>
                    </div>
                    <div class="txt">
                        <div class="ivl22">산업분야</div>
                        <div><SelectComp type="text" cdId="PRO105" :value="param.industryDetail.substr(0,1)"/> / <SelectComp type="text" cdId="PRO143" :value="param.industryDetail"/></div>
                    </div>
                    <div class="txt">
                        <div>지정공급기업</div>
                        <div class="list">
                            <ul class="duty-list">
                                <li v-for=" sco in param.scoFieldList" :key="sco.corpSeq">
                                    <span>
                                        {{sco.corpNm}}
                                    </span>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>	
            
            <div v-if="param.parentProjectSeq" class="member">위 정보로 수정하시겠습니까?</div>
            <div v-else class="member">위 정보로 등록하시겠습니까?</div>
            <div class="select-btn">
                <div class="btn" @click="$emit('close', false)">취소</div>
                <div class="btn" @click="saveParentProject()">완료</div>
            </div>			
        </div>
	</div>
</template>

<script>
export default {
    props : {param : Object},
	data() { 
        return { 
        }; 
    },
    mounted() {
        // var param = {
        //     mberSeq : this.$store.state.userInfo.mberSeq,
        // }
        // this.$.httpPost('/api/prj/sco/choice/getMyCorpName', {mberSeq : this.$store.state.userInfo.mberSeq})
        //     .then(res => {
        //         this.param.corpNm = res.data.corpNm;
        //         console.log(this.param);
        //     }).catch(this.$.httpErrorCommon);
        // console.log(this.param);
    },

    methods: {
        saveParentProject() {
            this.$.fileUpload('IMG', this.param.files,'/api/prj/sco/choice/saveParentProjectInfo', this.param)
                .then(res => {
                    if(res) {
						this.$emit('close', true);
					}
                }).catch(this.$.httpErrorCommon);
        }
	},
};
</script>
